import React from "react";
import "./Version.css";

export default class Version extends React.Component {
	// eslint-disable-next-line class-methods-use-this
	render() {
		return (
			<div className="Version">
				v1.16
			</div>
		);
	}
}
