import React from "react";
import "./DialogConfirmationWithTextField.css";
import Popup from "reactjs-popup";
import FormLine from "../button/FormLine.jsx";

export default class DialogConfirmationWithTextField extends React.Component {
	constructor(props) {
		super(props);

		this.state = {};
		this.afterConfirmation = this.afterConfirmation.bind(this);

		this.state = {
			value: null,
		};
	}

	afterConfirmation() {
		this.props.afterConfirmation(this.state.value);
		document.elementFromPoint(100, 0).click();
	}

	static cancel() {
		document.elementFromPoint(100, 0).click();
	}

	render() {
		return (
			<Popup
				trigger={this.props.trigger}
				modal
				closeOnDocumentClick
				className={"DialogConfirmationWithTextField"}
			>
				<div className={"DialogConfirmationWithTextField-wrapper"}>
					<h2>{this.props.text}</h2>
					<FormLine
						label={this.props.fieldName}
						value={this.state.value}
						onChange={(v) => this.setState({ value: v })}
					/>
					<div className={"bottom-right-buttons"}>
						<button
							className={"grey-background"}
							data-hover="Cancel"
							data-active=""
							onClick={DialogConfirmationWithTextField.cancel}>
							<span><i className="far fa-times-circle"/> Cancel</span>
						</button>
						<button
							data-hover="Yes"
							data-active=""
							onClick={this.afterConfirmation}>
							<span><i className="far fa-check-circle"/> Yes</span>
						</button>
					</div>
				</div>
			</Popup>
		);
	}
}
